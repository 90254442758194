<template>
  <div id="subpage-content">
    <ProductDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarDelivery") }}</h1>
        <div id="int-about-text">
          <img height="100" width="150" :src="getImageUrlbyName('Serv4')" />
          <a v-html="$t('message.DeliverText1')" id="pad"></a>
          <a v-html="$t('message.DeliverText2')" id="pad"></a>
          <a v-html="$t('message.DeliverText3')" id="pad"></a>
          <a v-html="$t('message.DeliverText4')" id="pad"></a>
          <a v-html="$t('message.DeliverText5')" id="pad"></a>
          <a v-html="$t('message.DeliverStep1')" id="pad"></a>
          <a v-html="$t('message.DeliverStep2')" id="pad"></a>
          <a v-html="$t('message.DeliverStep3')" id="pad"></a>
          <img class="deliveryimg" src="../../../assets/images/step3.png" />
          <a v-html="$t('message.DeliverStep4')" id="pad"></a>
          <img class="deliveryimg" src="../../../assets/images/step4.png" />
          <a v-html="$t('message.DeliverText6')" id="pad"></a>
          <a v-html="$t('message.DeliverText7')" id="pad"></a>
          <a v-html="$t('message.DeliverText8')" id="pad"></a>
          <a v-html="$t('message.DeliverText9')"></a>
        </div>
        <p></p>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/tLFRntFcD8A"
          frameborder="0"
          allowfullscreen=""
          id="iframe"
        ></iframe>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-card id="buy-card">
              <v-btn v-on="on" small id="buy-btn"
                >{{ $t("message.BuyService") }}
              </v-btn>
              <div id="history-card-text">
                {{ $t("message.MakeRequest") }}
              </div>
            </v-card>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import Feedback from "../../../components/Feedback";
import ProductDrawer from "../../../views/Navigations/Products/ProductDrawer";
export default {
  name: "Integration",
  components: {
    ProductDrawer,
    Feedback,
  },
};
</script>

<style>
.deliveryimg {
  height: auto;
  width: 100%;
  max-width: 600px;
}
</style>